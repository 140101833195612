import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CRUD } from "../../../collections";
import { addPractice, fetchPractices, removePractice } from "../../../actions";

import Layout from "../../../components/layout";
import SubMenu from "../../../components/layout_logged_in/subMenu";

const Index = () => {
  const dispatch = useDispatch();
  const [practice_name, update_practice_name] = useState("");
  const practices = useSelector((state) => state.practices);

  function handleAddPractice() {
    console.log("practice_name");
    console.log(practice_name);
    dispatch(addPractice(practice_name));
    update_practice_name("");
  }

  function handleRemovePractice(practice) {
    dispatch(removePractice(practice));
  }

  useEffect(() => {
    dispatch(fetchPractices());
  }, []);

  return (
    <Layout>
      <SubMenu>
        <CRUD
          header={"Practice"}
          add={{
            placeholder: "Practice Name",
            value: practice_name,
            onChange: update_practice_name,
            add: handleAddPractice,
            button_text: "Add Practice",
          }}
          table={{
            header_array: ["Name", "ID", "Created", "Remove?"],
            content: practices,
            handleRemoveEntry: handleRemovePractice,
          }}
        />
      </SubMenu>
    </Layout>
  );
};

export default Index;
