import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { Icon, Sidebar, Menu, Container, Divider } from "semantic-ui-react";
import logo from "../../images/icon.png";
import Header from "./header";
import "./layout.css";

const SubMenu = ({ children }) => {
  return (
    <div>
      <Container>
        <div style={{ paddingTop: 30 }}>{children}</div>
      </Container>
    </div>
  );
};

export default SubMenu;
